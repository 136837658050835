import "swiper/dist/css/swiper.css";
import Swiper from "swiper";
var $ = require("jquery");
import Player from "@vimeo/player";
import AOS from "aos";
import List from "list.js";
import Chart from "chart.js";
import * as d3 from "d3";
// import "lazysizes/plugins/bgset/ls.bgset";
import { stringify } from "querystring";

// App main
const main = async () => {
  //Import our CSS
  const styles = await import(
    /* webpackChunkName: "styles" */ "../css/app.pcss"
  );
  const lazySizes = await import(
    /* webpackChunkName: "lazySizes" */ "lazysizes"
  );
  await import("lazysizes/plugins/bgset/ls.bgset");

};

// Execute async function
main().then(value => {
  var deltagerIndex = 1,
    counter = 0,
    window_width,
    window_height,
    label,
    gammel_label,
    desktop,
    phone,
    tablet,
    ticking,
    map,
    kontorFilter = [],
    rollerFilter = [],
    bransjeFilter = [],
    typeFilter = [],
    byFilter = [],
    temaFilter = [],
    kursList,
    standardBy,
    standardTema,
    maskCache = {},
    logoDimensions = {
      width: 150,
      height: 34
    },
    $body = $("body"),
    $videoTop = $(".bg--video video"),
    $sections = $("section"),
    player,
    outputSeconds;

  function onTrackedVideoFrame(currentTime, duration){
    var seconds = Math.floor(currentTime % 60);
    if(seconds < 10){
      outputSeconds = "0:0"+seconds;
    }else{
      outputSeconds = "0:"+seconds;
    }
    $("#time").text(outputSeconds); //Change #current to currentTime
    $("#fullTime").text("0:" + Math.floor(duration % 60));
  }
  if($(".bg--video").length){
    if(!$(".bg--video video").paused){
      $(".bg--video img").addClass("hidden");
      console.log("Video Playing");
    }
    $(document).ready(function(){
      $("#theVideo").on(
        "timeupdate",
        function(event){
          onTrackedVideoFrame(this.currentTime, this.duration);
        });
    });
  }

  if($("#tour-list").length){
    $.ajax({
      type: 'GET',
      url: 'https://synlighetfrontend.azurewebsites.net/api/TourProgramme/Search',
      dataType:"jsonp",
      crossDomain: true,
      error: function(data) {
          console.error(data);
      },
      success: function(data) {
          for (let index = 0; index < data.length; index++) {
              const element = data[index];
              let tourId = element.TourId;
              let nameId = element.Name;
              let string = '<div class="text-xl"><span class="text-blue-100 text-1xl">'+tourId+' </span> - '+ nameId + '</div>'
              $("#tour-list").append(string);
              $(".lds-ring").hide();
          }
      }
  });
  }
  if ($("#people").length) {
    var options = {
        valueNames: ["name", { data: ["kontor"] }, { data: ["roller"] }]
      },
      filterList = new List("people", options);
  } else if ($("#results").length) {
    var options = {
        valueNames: [{ data: ["bransje"] }, { data: ["type"] }]
      },
      filterList = new List("results", options);
  }
  if ($("#allekurs").length) {
    standardTema = $(".js--firstTema").attr("data-default");
    standardBy = $(".js--firstBy").attr("data-default");
    $(".js--firstBy").html(standardBy);
    $(".js--firstTema").html(standardTema);
  }
  function detect_size() {
		window_width = $(window).width();
		window_height = $(window).height();

		if (window_width < 600) {
			label = 'phone';
			if (gammel_label !== label) {
				//$html.addClass('phone').removeClass('desktop tablet');
				desktop = tablet = false;
				phone = true;
			}
		} else if (window_width < 1025) {
			label = 'tablet';
			if (gammel_label !== label) {
				//$html.removeClass('phone').addClass('desktop tablet');
				desktop = phone = false;
				tablet = true;
			}
		} else {
			label = 'desktop';
			if (gammel_label !== label) {
				//$html.removeClass('phone tablet').addClass('desktop');
				desktop = true;
				tablet = phone = false;
			}
		}
		if (gammel_label !== label) {
			var video = label == 'phone' ? 'mobil' : 'desktop';
			$(".bg--video source").each(function( index ) {

				var src = $(this).attr('data-'+video) || $(this).attr('data-desktop') || null,
					$parent = $(this).parent();
          //console.log(src);
          if (src) {
            $parent.attr('src', src);

          }
      });
      $(".css-kurs-video source").each(function( index ) {

				var src = $(this).attr('data-'+video) || $(this).attr('data-desktop') || null,
					$parent = $(this).parent();
          //console.log(src);
          if (src) {
            $parent.attr('src', src);
          }
      });

		}
		gammel_label = label;
		ticking = false;
	}
  function changeDeltagerIndex(remIndex) {
    deltagerIndex = deltagerIndex - 1;
    $(".js--deltager").each(function(i, elm) {
      var index = parseInt($(this).attr("data-deltager"));
      var newIndex = index - 1;
      if (index > remIndex) {
        $(this).attr("data-deltager", newIndex);
        $(this)
          .find(".js--num")
          .html(index);
        $("[name*=Participants]", elm).each(function() {
          var name = $(this).attr("name");
          name = name.replace(/\[\d\]/, "[" + newIndex + "]");
          $(this).attr("name", name);
        });
      }
    });
  }
  function isEmail(email) {
    var regex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    return regex.test(email);
  }
  function filterPeople() {
    kontorFilter = [];
    $(".js--filter-kontor.js--active").each(function() {
      var filter = $(this).attr("data-filter");
      kontorFilter.push(filter);
    });
    if (kontorFilter.length) {
      $(".js--kontor").html(kontorFilter.join(", "));
    } else {
      $(".js--kontor").html("alle");
    }
    rollerFilter = [];
    $(".js--filter-roller.js--active").each(function() {
      var filter = $(this).attr("data-filter");
      rollerFilter.push(filter);
    });
    if (rollerFilter.length) {
      $(".js--roller").html(rollerFilter.join(", "));
    } else {
      $(".js--roller").html("alle");
    }

    if (kontorFilter.length || rollerFilter.length) {
      filterList.filter(function(item) {
        let kontorMatch = kontorFilter.length === 0,
          rolleMatch = rollerFilter.length === 0;
        item
          .values()
          .kontor.split(",")
          .forEach(kontor => {
            if (kontorFilter.indexOf(kontor) !== -1) {
              kontorMatch = true;
            }
          });
        item
          .values()
          .roller.split(",")
          .forEach(roller => {
            if (rollerFilter.indexOf(roller) !== -1) {
              rolleMatch = true;
            }
          });
        return kontorMatch && rolleMatch;
      });
    } else {
      filterList.filter();
    }
  }
  function filterCaser() {
    bransjeFilter = [];
    $(".js--filter-bransje.js--active").each(function() {
      var filter = $(this).attr("data-filter");
      bransjeFilter.push(filter);
    });
    if (bransjeFilter.length) {
      $(".js--bransje").html(bransjeFilter.join(", "));
    } else {
      $(".js--bransje").html("alle");
    }

    typeFilter = [];
    $(".js--filter-type.js--active").each(function() {
      var filter = $(this).attr("data-filter");
      typeFilter.push(filter);
    });
    if (typeFilter.length) {
      $(".js--type").html(typeFilter.join(", "));
    } else {
      $(".js--type").html("alle");
    }

    if (bransjeFilter.length || typeFilter.length) {
      filterList.filter(function(item) {
        let bransjeMatch = bransjeFilter.length === 0,
          typeMatch = typeFilter.length === 0,
          bransje = item.values().bransje,
          type = item.values().type;
        if (bransje) {
          bransje.split(",").forEach(bransje => {
            if (bransjeFilter.indexOf(bransje) !== -1) {
              bransjeMatch = true;
            }
          });
        }
        if (type) {
          type.split(",").forEach(type => {
            if (typeFilter.indexOf(type) !== -1) {
              typeMatch = true;
            }
          });
        }
        return bransjeMatch && typeMatch;
      });
    } else {
      filterList.filter();
    }
  }
  function filterKurs() {
    byFilter = [];
    standardBy = $(".js--firstBy").attr("data-default");
    $(".js--filter-by.js--active").each(function() {
      var filter = $(this).attr("data-filter");
      byFilter = [filter];
    });
    if (byFilter.length) {
      $(".js--firstBy").html(byFilter[0]);
    } else {
      $(".js--firstBy").html(standardBy);
    }

    temaFilter = [];
    standardTema = $(".js--firstTema").attr("data-default");
    $(".js--tema.js--active").each(function() {
      var filter = $(this).attr("data-filter");
      temaFilter = [filter];
    });
    if (temaFilter.length) {
      $(".js--firstTema").html(temaFilter[0]);
    } else {
      $(".js--firstTema").html(standardTema);
    }

    if (byFilter.length || temaFilter.length) {
      kursList.filter(function(item) {
        let byMatch = byFilter.length === 0,
          temaMatch = temaFilter.length === 0;
        item
          .values()
          .by.split(",")
          .forEach(by => {
            if (byFilter.indexOf(by) !== -1) {
              byMatch = true;
            }
          });
        item
          .values()
          .tema.split(",")
          .forEach(tema => {
            if (temaFilter.indexOf(tema) !== -1) {
              temaMatch = true;
            }
          });
        return byMatch && temaMatch;
      });
    } else {
      kursList.filter();
    }
  }

  function initMap() {
    var position = officeMaps[0].pos;
    map = new google.maps.Map(document.getElementById("map"), {
      zoom: 16,
      center: position
    });
    var marker = new google.maps.Marker({ position: position, map: map });
  }
  function blendLogoMasks(isDark, amount) {
    if (maskCache.isDark === isDark && maskCache.amount === amount) {
      return;
    }
    var alt = {};
    var main = {};
    if (isDark) {
      alt.y = amount + 1;
      alt.height = logoDimensions.height - amount;
      main.y = 0;
      main.height = amount;
    } else {
      alt.y = 0;
      alt.height = amount;
      main.y = amount + 1;
      main.height = logoDimensions.height - amount;
    }
    $("#logo-alt-mask rect").attr({
      y: alt.y * diff,
      height: alt.height * diff
    });
    $("#logo-main-mask rect").attr({
      y: main.y * diff,
      height: main.height * diff
    });
    maskCache.isDark = isDark;
    maskCache.amount = amount;
  }
  // This would generate a logo with the first 100px of white
  // and the rest as dark blue
  // blendLogoMasks(true, 33);
  let viewBox = $(".css--main-logo svg")
    .eq(0)
    .attr("viewBox")
    .split(" ");
  viewBox = viewBox[viewBox.length - 1];
  const diff =
    viewBox /
    $(".css--main-logo svg")
      .eq(0)
      .height();
  function generateSectionsMap() {
    var sections = [];
    $("section").each(function() {
      var top = $(this).offset().top;
      sections.push({
        $el: this,
        end: top + $(this).outerHeight(),
        isDark: $(this).hasClass("js-section-dark")
      });
    });
    return sections;
  }
  function getUrlParameter(sParam) {
    var sPageURL = window.location.search.substring(1),
      sURLVariables = sPageURL.split("&"),
      sParameterName,
      i;

    for (i = 0; i < sURLVariables.length; i++) {
      sParameterName = sURLVariables[i].split("=");

      if (sParameterName[0] === sParam) {
        return sParameterName[1] === undefined
          ? true
          : decodeURIComponent(sParameterName[1]);
      }
    }
  }

  function updateLogo(sections) {
    var scrollOffset = $body.scrollTop();
    var logoStart = $(".css--main-logo").offset().top;
    var logoEnd = logoStart + logoDimensions.height;
    var section;
    $.each(sections, function(index, section) {
      if (section.end >= logoStart) {
        if (
          section.end <= logoEnd &&
          sections[index + 1] &&
          sections[index + 1].isDark !== section.isDark
        ) {
          // Logo is on a cross-section, the first element in the
          // blend gets the amount of pixels in the section not
          // overlapping the logo. We only do this if the sections
          // have a different background, otherwise we treat it as
          // a single section.
          blendLogoMasks(section.isDark, section.end - logoStart);
        } else {
          // Logo is on a single section, the first element in the
          // blend can have the entire height of the logo.
          blendLogoMasks(section.isDark, logoDimensions.height);
        }
        return false;
      }
    });
  }
  var sections = generateSectionsMap();
  updateLogo(sections);
  $(window).on("scroll", function() {
    updateLogo(sections);
  });
  if ($("#templates").length) {
    var template = document.getElementById("templates").innerHTML;
    $(".js--deltagerListe").append(
      template.replace(/{id}/g, 0).replace("{num}", 1)
    );

    $(document).on("click", ".js--addDeltager", function(e) {
      $(".js--deltagerListe").append(
        template
          .replace(/{id}/g, deltagerIndex)
          .replace("{num}", deltagerIndex + 1)
      );
      deltagerIndex++;
    });
    $(document).on("click", ".js--removeDeltager", function(e) {
      var remIndex = parseInt(
        $(this)
          .parent(".js--deltager")
          .attr("data-deltager")
      );
      $(this)
        .parent(".js--deltager")
        .remove();
      changeDeltagerIndex(remIndex);
    });
  }
  // if($(".css--filters").length){
  // 	var maxWidth = 0;
  // 	var $element;
  // 	$(".js--tema").each(function(){
  // 		$element = $(this);
  // 		if($element.width() > maxWidth){
  // 			maxWidth = $element.width();
  // 		}
  // 	});
  // 	$(".js--firstTema").width(maxWidth);
  // }

  if ($("#skills").length) {
    var ctx = document.getElementById("skills");
    var myChart = new Chart(ctx, {
      type: "radar",
      data: {
        labels: skillPoints,
        datasets: [
          {
            data: skillLabels,
            backgroundColor: ["rgba(174,57,73, 0.4)"],
            borderColor: ["rgba(174,57,73, 0.0)"],
            borderWidth: 1
          }
        ]
      },
      options: {
        lineStyling: {
          lineTension: [3]
        },
        scale: {
          ticks: {
            beginAtZero: true,
            min: 0,
            stepSize: 1
          },
          pointLabels: {
            fontSize: 16
          },
          gridLines: {
            circular: true,
            color: "#8984BD"
          },
          angleLines: {
            color: "#8984BD",
            borderDash: [2, 10]
          }
        },
        legend: {
          display: false
        }
      }
    });
  }
  $("#kursBestilling").submit(function(e) {
    e.preventDefault();
    var request = new XMLHttpRequest();
    var form = $(this).get(0);
    let redirect = location.href;
    request.open("POST", form.getAttribute("action"), true);
    var formData = new FormData(form);
    request.onload = function() {
      console.log(request);
      if (request.status >= 200 && request.status < 400) {
          var resp = request.responseText;
          var jsonResponse = JSON.parse(resp);
          console.log(jsonResponse);
          //do shit with jsonResponse
          location.href = "/takk-kurs?redir=" + redirect;
      }else{
        console.error(request);
      }
    }
    request.send(formData);

    // let form = $(this);
    // let url = form.attr("action");
    // let redirect = location.href;

    // $.ajax({
    //   type: "POST",
    //   url: url,
    //   data: form.serialize(),
    //   crossDomain: true,
    //   error: function(data) {
    //     console.error("ERROR", data)
    //   },
    //   success: function(data) {
    //     location.href = "/takk-kurs?redir=" + redirect;
    //   }
    // });
  });
  $(document).mouseup(function(e) {
    var container = $(".js--temaDropdown");

    // if the target of the click isn't the container nor a descendant of the container
    if (!container.is(e.target) && container.has(e.target).length === 0) {
      container.removeClass("js--open");
    }
  });
  $(document).mouseup(function(e) {
    var container = $(".css--filterDropdown");

    // if the target of the click isn't the container nor a descendant of the container
    if (!container.is(e.target) && container.has(e.target).length === 0) {
      container.removeClass("js--open");
    }
  });
  $(document).on("click", ".js--openFilters", function(e) {
    var drop = $(this).closest(".css--filterDropdown");
    if (drop.hasClass("js--open")) {
      drop.removeClass("js--open");
    } else {
      drop.addClass("js--open");
    }
  });
  $(document).on("click", ".js--firstTema, .js--firstBy", function(e) {
    var drop = $(this).closest(".js--temaDropdown");
    if (drop.hasClass("js--open")) {
      drop.removeClass("js--open");
    } else {
      drop.addClass("js--open");
    }
  });
  $(document).on("click", ".js--scrollTo", function(e) {
    e.preventDefault();
    let scrollElement = $(this).attr("href");
    $([document.documentElement, document.body]).animate(
      {
        scrollTop: $(scrollElement).offset().top
      },
      700
    );
  });
  $(document).on(
    "click",
    ".js--filter-kontor, .js--filter-roller,  .js--filter-bransje, .js--filter-type",
    function(e) {
      var _this = $(this);
      if (_this.hasClass("js--active")) {
        _this.removeClass("js--active");
      } else {
        _this.addClass("js--active");
      }
      if (_this.is(".js--filter-kontor, .js--filter-roller")) {
        filterPeople();
      } else if (_this.is(".js--filter-bransje, .js--filter-type")) {
        filterCaser();
      }
    }
  );

  $(document).on("click", ".js--filter-by", function(e) {
    $(".js--temaDropdown").removeClass("js--open");
    var _this = $(this);
    if (_this.hasClass("js--active")) {
      $(".js--filter-by.js--active").removeClass("js--active");
    } else {
      $(".js--filter-by.js--active").removeClass("js--active");
      _this.addClass("js--active");
    }
    filterKurs();
  });
  $(document).on("click", ".js--tema", function(e) {
    $(".js--temaDropdown").removeClass("js--open");
    var _this = $(this);
    if (_this.hasClass("js--active")) {
      $(".js--tema.js--active").removeClass("js--active");
    } else {
      $(".js--tema.js--active").removeClass("js--active");
      _this.addClass("js--active");
    }
    filterKurs();
  });

  $(document).on("input", ".js--email", function() {
    if (!isEmail($(this).val())) {
      $(this)
        .addClass("css--invalid")
        .removeClass("css--valid");
    } else {
      $(this)
        .addClass("css--valid")
        .removeClass("css--invalid");
    }
  });
  $(document).on("input", ".js--input", function() {
    if ($(this).val() == "") {
      $(this).removeClass("js--hasText css--valid css--invalid");
    } else {
      $(this).addClass("js--hasText");
    }
  });
  $(document).on("click", ".js--finishDeltagere", function() {
    $(this)
      .addClass("bg-grey-200")
      .removeClass("bg-green-200 cursor-pointer");
    $(this)
      .find("p")
      .html("Fyll ut fakturainformasjon");
    $(".js--bestilling").animate(
      {
        height: $(".js--bestilling").get(0).scrollHeight
      },
      250,
      function() {
        $(".js--bestilling").height("auto");
      }
    );

    var orderNameInput = $('input[name="OrderName"]');
    var orderEmailInput = $('input[name="OrderEmail"]');
    var firstParticipantName = $('input[name="Participants[0].Name"]').val();
    var firstParticipantEmail = $('input[name="Participants[0].Email"]').val();

    orderNameInput.val(firstParticipantName).addClass("js--hasText");
    orderEmailInput.val(firstParticipantEmail).addClass("js--hasText");
  });

  $(document).on("click", ".js--tableList", function(e) {
    $(".js--tableList")
      .addClass("text-grey-200")
      .removeClass("text-black-300");
    $(this)
      .addClass("text-black-300")
      .removeClass("text-grey-200");
    var attr = $(this).attr("data-index");
    $(".js--tableContent").addClass("hidden");
    $(".js--tableContent[data-content='" + attr + "']").removeClass("hidden");
  });
  $(document).on("click", ".js--menu-toggle", function(e) {
    if ($(this).hasClass("open")) {
      $(this).removeClass("open");
      $("nav")
        .addClass("opacity-0 pointer-events-none transition-delay")
        .removeClass("opacity-100 pointer-events-auto ");
      $("main").removeClass("blur transition-delay");
    } else {
      $(this).addClass("open");
      $("nav")
        .removeClass("opacity-0 pointer-events-none transition-delay")
        .addClass("opacity-100 pointer-events-auto");
      $("main").addClass("blur transition-delay");
    }
  });
  $(document).on("click", ".js--form-next", function(e) {
    let summary = "";
    $("input[data-label], textarea[data-label], input[data-label]").each(
      function(i, elm) {
        let $elm = $(elm);
        let val = $elm.val();
        let dataLabel = $elm.attr("data-label");
        let text =
          '<p class="font-semibold">' + dataLabel + ":</p><p>" + val + "</p>";
        summary = summary + text;
        $("#oppsummering").html(summary);
      }
    );
  });
  $(document).on("click", ".level-1", function(e) {
    var $window = $(window);
    var windowsize = $window.width();
    if (windowsize < 505) {
      if ($(this).hasClass("js--open")) {
      } else {
        e.preventDefault();
        $(this).addClass("js--open");
        $("nav").addClass("js--open");
        $(".js--submenu").removeClass("sm:h-0");
      }
    }
  });
  $(document).on("click", ".js--menu-back", function(e) {
    $(".level-1").removeClass("js--open");
    $("nav").removeClass("js--open");
    $(".js--submenu").addClass("sm:h-0");
  });

  $(document).on("click", "#hide-grid", function(e) {
    if ($(this).hasClass("open")) {
      $(this).removeClass("open");
      $(".mal-grid").addClass("hidden");
    } else {
      $(this).addClass("open");
      $(".mal-grid").removeClass("hidden");
    }
  });
  $(document).on("click", ".js--kontakt-kontor", function(e) {
    let by = $(this).attr("data-kontor");
    $(".text-black-300")
      .removeClass("text-black-300")
      .addClass("text-grey-200");
    $(this)
      .addClass("text-black-300")
      .removeClass("text-grey-200");
    $(".kart-wrapper").addClass("hidden");
    $('.kart-wrapper[data-kontor="' + by + '"]').removeClass("hidden");
    let index = officeMaps.findIndex(x => x.sted === by);
    map.panTo(officeMaps[index].pos);
    let newMarker = new google.maps.Marker({
      position: officeMaps[index].pos,
      map: map
    });
  });
  $(document).on("click", ".js--play", function(e) {
    e.preventDefault();
    this.classList.add("hidden");
  });

  $(document).on("click", "#play-video", function(e) {
    this.classList.add("hidden");
    $("#stop-video").removeClass("hidden");
    $videoTop.get(0).play();
  });
  $(document).on("click", "#stop-video", function(e) {
    this.classList.add("hidden");
    $("#play-video").removeClass("hidden");
    $videoTop.get(0).pause()
  });
  $(document).on("click", "#mute-video", function(e) {
    this.classList.add("hidden");
    $("#unmute-video").removeClass("hidden");
    $videoTop.prop('muted', true);
  });
  $(document).on("click", "#unmute-video", function(e) {
    this.classList.add("hidden");
    $("#mute-video").removeClass("hidden");
    $videoTop.prop('muted', false);
    $videoTop.prop("volume", 0.3);

  });
  $(document).on("click", ".js--getMoreKurs", function(e) {
    if ($(".js--kurs-list").hasClass("showAll")) {
      $(".js--kurs-list").removeClass("showAll");
      $(this).html("Vis flere kurs");
    } else {
      $(".js--kurs-list").addClass("showAll");
      $(this).html("Vis færre kurs");
    }
  });
  let screenWidth = $(window).width();
  if ($(".tjenestekort").length) {
    if (screenWidth < 501) {
      $(".swiper-container.tjenestekort").each(function(i, elm) {
        const extraClass = "swiper-slideshow" + i;
        $(elm)
          .parent()
          .addClass(extraClass);
        const swiper = new Swiper("." + extraClass + " .swiper-container", {
          spaceBetween: 10,
          slidesPerView: 1,
          on: {
            slideChange: function() {}
          },
          breakpoints: {
            520: {
              slidesPerView: 1,
              spaceBetween: 10
            }
          },
          navigation: {
            nextEl: "." + extraClass + " .swiper-btn-next",
            prevEl: "." + extraClass + " .swiper-btn-prev"
          }
        });
      });
    } else {
      $(".swiper-container").removeClass("px-2/12 -mx-1/12");
      $(".swiper-slide")
        .addClass("col-3_md-6")
        .removeClass("swiper-slide");
      $(".swiper-wrapper")
        .addClass("grid-center-equalHeigth")
        .removeClass("swiper-wrapper");
    }
  }

  $(".swiper-container.slideshow").each(function(i, elm) {
    const extraClass = "swiper-slideshow" + i;
    $(elm)
      .parent()
      .addClass(extraClass);
    const swiper = new Swiper("." + extraClass + " .swiper-container", {
      spaceBetween: 20,
      slidesPerView: 3,
      height: 400,
      loop: false,
      centeredSlides: true,
      on: {
        slideChange: function() {}
      },
      breakpoints: {
        520: {
          slidesPerView: 1,
          spaceBetween: 20
        }
      },
      navigation: {
        nextEl: "." + extraClass + " .swiper-btn-next",
        prevEl: "." + extraClass + " .swiper-btn-prev"
      },
      pagination: {
        el: "." + extraClass + " .swiper-index",
        modifierClass: "pagination",
        type: "fraction",
        currentClass: "text-red-100",
        totalClass: "pagination-total"
      }
    });
  });
  $(".swiper-container.nokkeltall").each(function(i, elm) {
    const extraClass = "swiper-nokkeltall" + i;
    $(elm)
      .parent()
      .addClass(extraClass);
    const swiper = new Swiper("." + extraClass + " .swiper-container", {
      spaceBetween: 20,
      slidesPerView: 3,
      height: 400,
      loop: false,
      centeredSlides: true,
      on: {
        slideChange: function() {}
      },
      navigation: {
        nextEl: "." + extraClass + " .swiper-btn-next",
        prevEl: "." + extraClass + " .swiper-btn-prev"
      },
      breakpoints: {
        520: {
          slidesPerView: 2,
          spaceBetween: 20
        }
      }
    });
  });
  $(".swiper-container.logovegg").each(function(i, elm) {
    const extraClass = "swiper-logovegg" + i;
    $(elm)
      .parent()
      .addClass(extraClass);
    const swiper = new Swiper("." + extraClass + " .swiper-container", {
      spaceBetween: 40,
      slidesPerView: 8,
      loop: true,
      noSwiping: true,
      speed: 3000,
      centeredSlides: true,
      autoplay: {
        delay: 0,
        disableOnInteraction: true
      },
      breakpoints: {
        769: {
          slidesPerView: 3,
          spaceBetween: 20
        }
      },
      on: {
        slideChange: function() {}
      }
    });
  });
  function randomIntFromInterval(min, max) {
    // min and max included
    return Math.floor(Math.random() * (max - min + 1) + min);
  }
  $(".js--dot").each(function(i, elm) {
    let left = randomIntFromInterval(5, 95);
    let top = randomIntFromInterval(5, 100);
    let size = randomIntFromInterval(5, 60);
    $(elm).css({
      top: top + "%",
      left: left + "%",
      height: size + "px",
      width: size + "px"
    });
  });
  $(".js--dot-ups").each(function(i, elm) {
    let left = randomIntFromInterval(5, 95);
    let top = randomIntFromInterval(5, 95);
    let size = randomIntFromInterval(5, 15);
    let opacity = Math.random();
    $(elm).css({
      top: top + "%",
      left: left + "%",
      height: size + "px",
      width: size + "px",
      opacity: opacity
    });
  });
  $(".js--kursitem").each(function(i, elm) {
    let height = $(elm).height();
    $(".js--imgWrapper").css({
      height: height + "px",
      width: height + "px"
    });
  });

  $(".aos-delay-folkentry").each(function(i, elm) {
    if (counter == 6) {
      counter = 0;
    }
    counter = counter + 1;
    console.log(counter);
    $(elm).attr("data-aos-delay", counter + "00");
  });
  $(".aos-delay-100").each(function(i, elm) {
    if (counter == 4) {
      counter = 0;
    }
    counter = counter + 1;
    console.log(counter);
    $(elm).attr("data-aos-delay", counter + "00");
  });

  if (window.csvFile) {
    d3.csv(csvFile).then(makeChart);

    function makeChart(csv) {
      const keys = Object.keys(csv[0]);
      let datasets = [];
      const colors = [
        "#F2A943",
        "#00B0EB",
        "#4CB774",
        "#E64451",
        "#A76E26",
        "#6A288A",
        "#164795",
        "#33865E",
        "#B12A3C"
      ];
      keys.forEach((key, i) => {
        datasets.push({
          data: csv.map(d => d[key]),
          label: key,
          borderColor: colors[i],
          backgroundColor: "transparent"
        });
      });

      const labels = datasets.slice(datasets.length - 1)[0];
      datasets = datasets.slice(0, datasets.length - 1);
      var yLabels = csv.map(function(d) {
        return d[Object.keys(d)[2]];
      });

      var xLabels = csv.map(function(d) {
        return d[Object.keys(d)[1]];
      });

      var chart = new Chart("chart", {
        type: "line",
        data: {
          labels: labels.data,
          datasets: datasets
        },
        options: {
          tooltips: {
            mode: "index",
            intersect: false
          }
        }
      });
    }
  }

  $(document).ready(function() {
    $body.removeAttr("style");
    setTimeout(() => {
      $body.addClass("loaded");
    }, 1000);
    $("#graph-mask rect").addClass("animateGraph");
    detect_size();
  });
  window.onresize = function() {
    if (!ticking) {
      requestAnimationFrame(detect_size);
      ticking = true;
    }
  };

  detect_size();
  AOS.init({
    once: true
  });
  if ($("#map").length) {
    initMap();
  }
});
